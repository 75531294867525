import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../style/register.scss';
import '../style/constant.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, { Marker } from 'react-map-gl';
import Context from '../tools/context';
import {siteContext, isFirstTimeLogin,
  markFirstTimeLoginDone, getPartnerDenomination,
  getQueryString} from '../tools/toolbox';
import {getFields, getCheckboxes, getFlatFieldKeys} from '../tools/formFieldsDatabase';
import {
  addressGeocoding,
  createUser,
  logToOauth,
  authCall,
  uploadImage, deleteImage,
} from '../tools/apitools';
import Loader from '../components/Loader';
import Logo from '../components/Logo';
import FormField from '../components/formField/';
import CommentCaMarche from '../components/CommentCaMarche';
import Footer from '../components/Footer';
import RegisterPageHeader from '../components/RegisterPageHeader';
import AppLink from '../components/AppLink';
import AppButton from '../components/AppButton';
import {clientT} from '../tools/i18n';
import {formatDate} from '../Utils/dateUtils';

class Register extends Component {
  constructor() {
    super();

    this.errorMessages = {
      errorAlert: 'Merci de corriger les erreurs signalées en rouge',
      emailErrorMessage: 'Compte existant',
      passwordError: 'Les deux mots de passe ne correspondent pas',
      invalidAddressMessage:
        'Attention adresse inexistante merci de vérifier adresse et code postal avant de confirmer',
      registrationNumberErrorMessage: 'Le SIRET doit contenir 14 chiffres',
      phoneError: 'Le numero de téléphone n\'est pas valide',
      emptyFieldError: "Merci de saisir l'ensemble des champs obligatoires",
    };

    this.mapParams = {
      width: 300,
      height: 300,
    };

    this.registered = getQueryString('registered');
    this.iframe = getQueryString('iframe');
    if (!this.registered || this.iframe){
      siteContext().accessToken = null;
      siteContext().refreshToken = null;
    }
    if (this.iframe && Context?.partner?.sponsor === 'passprovence') {
      document.body.style.setProperty('--partnerFontFamily', "\"Zilla Slab\", serif");
    }
    this.state = {
      formData: {
        companyName: '', //Nom de l'établissement
        corporateName: '', //Raison sociale
        registrationNumber: '', //N° SIRET
        category: '',
        contactEmail: '',
        contactPhone: '',
        address: '',
        zipcode: '',
        city: '',
        description: '',
        password1: '',
        password2: '',
        eligibleTypeOfShop: null,
        optInMarketing: null,
        optInTermsAndConditions: null, // Acceptation CGU
        iban: '' || null,
        name: '',
        shortcode: null,
        tag: '',
        uuid: '',
      },
      //Error messages
      errorMessage: '',
      PasswordError: '',
      EmailError: '',
      registrationNumberErrorMsg: '',
      //Loader on/off
      loaderOn: false,
      loaderClassName: 'hide',
      //mapParams
      zoom: 15,
      showMap: false,
      validAddress: false,
      longitude: 5.4,
      latitude: 43.3,
      sellerAlreadyExist: false,
      sellerAccountAlreadyExist: false
    };
  }

  componentDidMount() {
    if (Context.partner.sponsor) {
      // document.title = 'Inscrivez-vous à l\'opération Tickets My Provence en 5 minutes seulement !';
    }
    if (this.registered === 'true' && Context.seller) {
      let newFormData = {...this.state.formData};
      let preloadedKeys = [
        'registrationNumber',
        'name',
      ];
      let preloadedDataKeys = ['legalname'];

      preloadedKeys.map((key) => newFormData[key] = Context.seller[key] || '');
      preloadedDataKeys.map((key) => {
        let dataKey = Context.seller.data?.find(obj => (obj.key === key));

        newFormData[key] = dataKey?.value || '';
      });
      this.setState({formData: newFormData});
    }
  }

  renderMap = () => {
    return (
      <div className='mapContainer'>
        <div className='mapWrapper'>
          <div>
            <div className='mapQuestion'>
              <p> Voici l'adresse indiquée.</p>
              <p>La position est-elle correcte?</p>
            </div>
          </div>
          <ReactMapGL
            width={this.mapParams.width}
            height={this.mapParams.height}
            zoom={this.state.zoom}
            longitude={this.state.longitude}
            latitude={this.state.latitude}
            mapStyle={'mapbox://styles/mapbox/streets-v11'}
            mapboxApiAccessToken='pk.eyJ1IjoibHZlcmRvbmsiLCJhIjoiY2s4OHY0b2llMDBkeTNpbXBhNmRnMmU3dCJ9.adHYqa4eOwGNKzBR9EJB7A'
            onViewportChange={(nextViewport) => {
              this.setState(nextViewport);
            }}
          >
            <Marker
              longitude={this.state.longitude}
              latitude={this.state.latitude}
            >
              <img
                className='mapMarker'
                alt='mapmarker'
                src='data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22%2368737d%22%3E%20%20%3Cdefs%2F%3E%20%20%3Cpath%20d%3D%22M%204%2017%20C%204%2022%2010%2028%2015%2028%20V%2032%20H%2017%20V%2028%20C%2022%2028%2028%2022%2028%2017%20H%2032%20V%2015%20H%2028%20C%2028%2010%2022%204%2017%204%20V%200%20H%2015%20V%204%20C%2010%204%204%2010%204%2015%20H%200%20V%2017%20H%2012%20V%2015%20H%207%20C%207%2011%2011%207%2015%207%20V%2012%20H%2017%20V%207%20C%2021%207%2025%2011%2025%2015%20C%2025%2015%2025%2015%2025%2015%20H%2020%20V%2017%20H%2025%20C%2025%2021%2021%2025%2017%2025%20V%2020%20H%2015%20V%2025%20C%2011%2025%207%2021%207%2017%20Z%22%2F%3E%3C%2Fsvg%3E'
              />
            </Marker>
          </ReactMapGL>
          <form onSubmit={this.handleCreateUser}>
            <div className='mapInputs'>
              <input
                required
                placeholder='adresse'
                id='address'
                value={this.state.formData.address}
                onChange={this.handleMapInputs}
              />
              <input
                required
                placeholder='Code postal'
                id='zipcode'
                value={this.state.formData.zipcode}
                onChange={this.handleMapInputs}
                minLength='5'
                maxLength='5'
              />
              <input
                required
                placeholder='Ville'
                id='city'
                value={this.state.formData.city}
                onChange={this.handleMapInputs}
              />
            </div>
            <div className='mapButtons'>
              <AppButton
                className='button button2'
                style={{ fontSize: '14px' }}
                event="Cancel register address"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showMap: false,
                  });
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                className={`button button1 ${
                  this.state.validAddress ? '' : 'disabled'
                }`}
                style={{ fontSize: '14px' }}
                type='submit'
                disabled={!this.state.validAddress}
                event="Confirm register address"
              >
                Confirmer
              </AppButton>
            </div>
          </form>
        </div>
      </div>
    );
  };

  handleChange = (e) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [e.target.id]: e.target.value
      },
    }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ errorMessage:"" });
    const {formData} = this.state;

    //check if registration number is compliant i.e length === 14 numbers
    if (formData.registrationNumber.toString().length !== 14) {
      this.setState({
        registrationNumberErrorMsg:
          this.errorMessages.registrationNumberErrorMessage,
      });
      // scroll window to registrationNumber input field
      document
        .querySelector('label[for=registrationNumber]')
        .scrollIntoView({ behavior: 'smooth' });
      return;
    } else {
      this.setState({
        registrationNumberErrorMsg: null,
      });
    }

    if (getFlatFieldKeys('register').includes('contactPhone')) {
      if (!formData.contactPhone?.number){
        this.setState({
          contactPhoneError:
          this.errorMessages.emptyFieldError
        });
        document.querySelector('label[for=contactPhone]').scrollIntoView({behavior:'smooth'});
        return;
      } else if (!formData?.contactPhone?.valid) {
      this.setState({
        contactPhoneError: this.errorMessages.phoneError,
      });
      document
        .querySelector('label[for=contactPhone]')
        .scrollIntoView({ behavior: 'smooth' });
      return;
      } else {
        this.setState({
          contactPhoneError: null,
        });
      }
    }

    if (!formData?.ownerPhone?.valid || !formData.ownerPhone?.number) {
      if (!formData.ownerPhone?.number) {
        this.setState({
          ownerPhoneError: this.errorMessages.emptyFieldError,
        });
        document
        .querySelector("label[for=ownerPhone]")
        .scrollIntoView({ behavior: "smooth" });
      return;
      }
      this.setState({
        ownerPhoneError: this.errorMessages.phoneError,
      });
      document
        .querySelector('label[for=ownerPhone]')
        .scrollIntoView({ behavior: 'smooth' });
      return;
    } else {
      this.setState({
        ownerPhoneError: null,
      });
    }

    //check if password 1 === password 2
    if (formData.password1 !== formData.password2) {
      this.setState({
        PasswordError: this.errorMessages.passwordError,
      });
      return;
    } else {
      this.setState({
        PasswordError: null,
      });
    }

    // switch loader on
    this.setState({
      showMap: true,
    });
  };

  handleMapInputs = async (e) => {
    if (e) {
      e.preventDefault && e.preventDefault();
      await this.handleChange(e);
    }
    if (
      this.state.formData.address.length > 2 &&
      this.state.formData.zipcode &&
      this.state.formData.city
    ) {
      const address =
        this.state.formData.address +
        ' ' +
        this.state.formData.zipcode +
        ' ' +
        this.state.formData.city;
      addressGeocoding(address)
        .then((data) => {
          if (data?.results?.length) {
            const { lat, lng } = data.results[0].geometry.location;
            this.setState((prevState) => ({
              longitude: lng,
              latitude: lat,
              validAddress: true,
            }));
          } else {
            this.setState({
              validAddress: false,
            });
          }
        })
        .catch(() => {
          this.setState({ validAddress: false });
        });
    }
  };

  handleCreateUser = (e) => {
    e.preventDefault();
    this.setState({
      loaderOn: true,
    });
    window.scrollTo(0, 0);
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });


    const { formData } = this.state;
    const bodyKey = [
      'loginEmail',
      'contactEmail',
      'iban',
      'registrationNumber',
      'category',
      'name',
      'shortcode',
      'ownerEmail'
    ];

    const excludeKey = ['password1', 'password2', 'data', 'shortcode', 'image', 'image2', 'image3'];

    let productDate = null;
    if (Context.partner && Context.partner.sponsor === 'passprovence' && formData.productDate) {
      productDate = formatDate(formData.productDate);
    }

    const body = {
      address: `${formData.address};${formData.city};${formData.zipcode}`, //"street;city;zipcode",
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      password: formData.password1,
      contactPhone: formData.contactPhone?.number,
      ownerPhone: formData.ownerPhone?.number,
      contactPhoneCode: formData.contactPhone?.code,
      ownerPhoneCode: formData.ownerPhone?.code,
      sellerData: {
        partner: Context.partner.sponsor,
        street: formData.address,
        // Only add productDate to sellerData if it's defined (i.e., sponsor is 'passprovence' and formData.productDate exists)
        ...(productDate && { productDate: productDate })      },
    };

    if (getQueryString('event')) {
      body.sellerData.event = getQueryString('event');
    }

    body.sellerData[`${Context.partner.sponsor}_status`] =
        Context.partner.features.requireValidation ? 'created' : 'accepted';

    if (Context.partner.parentCustomer) {
      body.sellerData[`${Context.partner.parentCustomer.key}_status`] =
          Context.partner.features.requireValidation ? 'created' : 'accepted';
    }

    Object.keys(formData).forEach((k) => {
      if (bodyKey.includes(k)) {
        body[k] = formData[k];
        return;
      }

      if (!body[k] && !body.sellerData[k] && !excludeKey.includes(k)) {
        body.sellerData[k] = formData[k];
      }
    });

    this.setState({ loaderOn: true });
    createUser(body)
      .then((data) => {
        if (data.id) {
          // login
          const login = formData.loginEmail;
          const password = formData.password1;
          // if (Context.partner.sponsor) {
          //   ACtrackEvent(Context.partner.sponsor, 'login', login);
          // }

          logToOauth(
            { login, password },
            () => {
              const randomImageVersion = Math.floor(Math.random() * 1000000);

              if (formData.image) {
                uploadImage(formData.image, data.id);

                const dataBody = {};
                dataBody['store_image'] = `https://cdn.cibler.io/static/seller/${data.id}/store.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.image2) {
                uploadImage(formData.image2, data.id, 2);

                const dataBody = {};
                dataBody['store_image_2'] = `https://cdn.cibler.io/static/seller/${data.id}/store_2.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.image3) {
                uploadImage(formData.image3, data.id, 3);

                const dataBody = {};
                dataBody['store_image_3'] = `https://cdn.cibler.io/static/seller/${data.id}/store_3.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.imagepartner) {
                uploadImage(formData.imagepartner, data.id, 1, true);
              }

              if (Context.partner
                && Context.partner.firstLoginRedirectToOffer
                && isFirstTimeLogin()) {
                markFirstTimeLoginDone();
                this.props.history.replace('/addproduct');
                return;
              }
              const homePage = Context.partner.dashboardNew?.actAsHomePage ? '/dashboard-new' : '/home';
              if (this.iframe) {
                window.open(`${homePage}?ta=`+siteContext().accessToken, "_blank");
              } else {
                this.props.history.replace(homePage);
              }

              return;
            },
            () => this.props.history.replace('/connexion')
          );
        } else {
          // alert("Compte existant");
          window.scrollTo(0, 0);
          let errmsg ="Une erreur est survenue pendant l'inscription"
          switch (data.description) {
            case 'SellerAccountAlreadyExists':
              return this.setState({
                showMap: false,
                sellerAlreadyExist: false,
                sellerAccountAlreadyExist: true,
                loaderOn: false
              });
            case 'SellerAlreadyExists':
              return this.setState({
                showMap: false,
                sellerAlreadyExist: true,
                sellerAccountAlreadyExist: false,
                loaderOn: false
              });
            default:
              break;
          }
          this.setState({
            showMap: false,
            errorMessage: errmsg,
            sellerAlreadyExist: false,
            sellerAccountAlreadyExist: false,
            loaderOn: false,
          });
        }
      })
      .catch(() => {
        window.scrollTo(0, 0);
        this.setState({
          showMap: false,
          errorMessage: "Une erreur est survenue pendant l'inscription",
          loaderOn: false,
        });
      });
  };

  render() {
    let partnerName;
    const textUnderline = Context.partner?.register?.textUnderline || false;
    switch (Context.partner.sponsor) {
      case 'stbrieucarmoragglo':
        partnerName = 'Saint Brieuc Armor Agglomération';
        break;
      case 'lavalagglo':
        partnerName = 'Laval Agglomération';
    }

    const filterFields = () => {
      const fields = getFields('register');

      if (this.registered === 'true')
        delete fields['Mes identifiants de connexion'];
      return fields;
    }

    const fields = filterFields();

    const background =
      Context.partner.register && Context.partner.register.customBackground
        ? {
            background: `url('/assets/register_background.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }
        : null;
    return (
      <>
        {!Context.partner?.register?.iframeMode &&
          <div
            style={{
              position: 'relative',
            }}
          >
            {Context.partner && Context.partner.register && Context.partner.register.customHeader && Context.partner.register.customHeader ?
              <RegisterPageHeader/>
              : (
                <Logo big style={{margin: '10px auto'}}/>
              )}
          </div>
        }
        <div className='RegisterPage_container' style={background}>
          {this.state.loaderOn ? <Loader /> : null}
          {Context.partner &&
          Context.partner.register &&
          !Context.partner.register.withoutEdito ? (
            <div className='register_flex'>
              <div
                className='register_edito'
                dangerouslySetInnerHTML={{
                  __html: Context.partner.register.editoHtml,
                }}
              />
            </div>
          ) : null}
          <div className='register_flex'>
            {Context.partner &&  Context.partner.register && Context.partner.register.formTicketCo_Title && (
              <div className='formTicketCo_Title'>
                <p>DEVENEZ PARTENAIRE </p>
                <p> C’EST SIMPLE, GRATUIT ET GAGNANT POUR TOUS !</p>
                <div className='cardText'>
                  <p>
                    COMPLÉTEZ<br/> CE  FORMULAIRE<br/> ET DEVENEZ PARTENAIRE
                    <br/> EN MOINS DE 2 MINUTES !
                  </p>
                  <p>UNE QUESTION?<br/> NOUS VOUS AIDONS<br/> au 09 80 80 37 49</p>
                </div>
              </div>
            )}
            {Context.partner?.register?.formTitle &&
              <div className="formTicketCo_Title" dangerouslySetInnerHTML={{__html: Context.partner.register.formTitle}} />
            }

            <div className='Content_wrapper'>
              {/* FORM START */}
              <h1
                className={this.state.loaderOn === false ? 'hide' : 'loaderOn'}
              >
                <Loader />
              </h1>
              <div className='signUpPage_wrapper'>
                <div className='signUpPage_Header'>
                  {!Context.partner.register ? (
                    <>
                      <h3>clientT('sign.signup')</h3>
                      <p>clientT('sign.signupText')</p>
                    </>
                  ) : (!Context.partner?.register?.iframeMode ? (
                    <div style={{textTransform: 'uppercase'}}>
                      {!Context.partner.register.formTicketCo_Title && clientT('common.signup')}
                    </div>
                  ) : null)}
                </div>
                {this.registered === "true" && Context.partner.sponsor === 'passprovence' &&
                  <div className={"signUpPage_SubHeader"}>
                    <h5 dangerouslySetInnerHTML={{__html: clientT('signUp.subHeader.description')}} />
                    <h5 dangerouslySetInnerHTML={{__html: clientT('signUp.subHeader.contact', {email: clientT('signUp.subHeader.email'), tel: clientT('signUp.subHeader.tel')})}} />
                  </div>
                }

                <form onSubmit={this.handleFormSubmit} className='formWrapper'>
                  {Object.keys(fields).map((k) => (
                    <div key={k.id}>
                      {k !== 'oneGroup' ? (
                        <div className='formSubTitle'>{clientT(`form.${k}`)}</div>
                      ) : null}
                      {fields[k].map((f) => (
                        <FormField
                          {...f}
                          key={f.id}
                          value={this.state.formData[f.id]}
                          errorMsg={
                            f.errorMsgKey ? this.state[f.errorMsgKey] : null
                          }
                          handleChange={
                            f.id === 'address' ||
                            f.id === 'zipcode' ||
                            f.id === 'city'
                              ? this.handleMapInputs
                              : this.handleChange
                          }
                          register
                        />
                      ))}
                    </div>
                  ))}
                  {getCheckboxes().map((f) => (
                    <FormField
                      {...f}
                      key={f.id}
                      type='checkbox'
                      value={this.state.formData[f.id]}
                      customError='Vous devez cocher cette case pour vous inscrire'
                      handleChange={this.handleChange}
                      i18n
                    />
                  ))}
                  {Context.partner && Context.partner.sponsor === 'passprovence' &&
                    <div className="signUpcheckboxes">
                      <label style={{margin: "5px 0 0 0", textAlign: "center"}}>
                        {clientT('optin.convention_text')} <a target="_blank" href="/documents/Convention_PASS_MYPROVENCE_2023_VF.pdf">{clientT('optin.convention_link')}</a>.
                      </label>
                    </div>
                  }
                  {this.state.errorMessage ? (
                      <div className='register_error'>
                        {this.state.errorMessage}
                      </div>
                  ) : null}
                  {this.state.sellerAccountAlreadyExist &&
                    <div style={{margin: "40px"}}>
                      <div className='register_error'>
                        Cette adresse email est déjà utilisée.
                      </div>
                      <div className='register_sellerAccountAlreadyExist'>
                        Accédez à votre espace en cliquant <AppLink href='/connexion'><span className={`${textUnderline && "text-decoration"}`}>ici</span></AppLink>.
                      </div>
                    </div>
                  }
                  {this.state.sellerAlreadyExist &&
                    <div style={{margin: "40px"}}>
                      <div className='register_error'>
                        Cette adresse email est déjà associée à un établissement.
                      </div>
                    </div>
                  }
                  <AppButton className='button button1' type='submit' event="Registration Submit">
                    {
                      Context.partner && Context.partner.register &&
                      Context.partner.register.signUpButtonText ? Context.partner.register.signUpButtonText :
                      this.registered === 'true' ? clientT('register.createNewSalePoint') : clientT('register.createPartnerAccount')
                    }
                  </AppButton>
                </form>
                {!Context.partner.register?.iframeMode &&
                  <p className='sigIn_passwordReinit'>
                    <AppLink href='/connexion'><span
                      className={`${textUnderline && "text-decoration"}`}>{clientT('common.login')}</span></AppLink>
                  </p>
                }
              </div>
            </div>
          </div>
          {this.state.showMap && this.renderMap()}
        </div>
        {Context.partner?.register?.commentCaMarche && <CommentCaMarche />}
      </>
    );
  }
}

export default withRouter(Register);
